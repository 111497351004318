import React from 'react';
import './Info.scss';
import News from "../News/News";
import ContactForm from "../ContactForm/ContactForm";

const Info = () => {
    return (
        <div className='info-background'>
            <News/>
            <ContactForm/>
        </div>
    );
};

export default Info;