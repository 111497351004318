import React from 'react';
import './Program.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faChartSimple } from '@fortawesome/free-solid-svg-icons'
import {BASE_URL} from "../../../../utils/const";

const Program = ({showInProgress, podcast, onClick}) => {
    const {thumbnail, title, author} = podcast;
    return (
        <div id='program' className='program' onClick={onClick}>
            <div className='cover-short'>
                <img alt={title} className=" image_ratio"
                     src={BASE_URL + thumbnail} />
            </div>
            <div className='info'>
                <span>{title}</span>
                <span>{author}</span>
                {showInProgress && <span>Reproduciendo</span>}
            </div>
            <div className='button-play'>
                <FontAwesomeIcon beatFade={showInProgress} icon={showInProgress ? faChartSimple : faCirclePlay} size={showInProgress ? '2x' :"2x"} />
            </div>
        </div>
    );
};

export default Program;