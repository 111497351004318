import React from 'react';
import './ContainerPodcast.scss';

const ContainerPodcast = ({children}) => {
    return (
        <div className='container-podcast'>
            {children}
        </div>
    );
};

export default ContainerPodcast;