import React from 'react';
import './Player.scss';
import Music from "../Music/Music";

const Player = ({podcast}) => {
    return (
        <div id='player' className='player'>
            <Music podcast={podcast} />
        </div>
    );
};

export default Player;