import React from 'react';
import './Title.scss'

const Title = ({children}) => {
    return (
        <div className='title'>
            {children}
        </div>
    );
};

export default Title;