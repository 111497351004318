import React, {useEffect, useState} from 'react';
import Title from "../Title/Title";
import './News.scss';

const News = () => {
    const [news, setNews] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://edukatel.cl/backend/api/noticias", requestOptions)
            .then(response => response.json())
            .then(result => setNews(result.reverse()))
            .catch(error => console.log('error', error));
    }, []);

    return (
        <>
            <Title>
                Noticias
            </Title>
            <div className={'news'}>
                {
                    news && news.map((newsletter, index) => {
                        return <div key={index}>
                            <h3>{newsletter.Titulo}</h3>
                            <div className='square'>
                                <div>
                                    <img
                                        src={'https://edukatel.cl/backend/storage/app/public/' + newsletter.Imagen}
                                        alt={newsletter.Titulo}/>
                                </div>
                                <p>{newsletter.Noticia}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    );
};

export default News;