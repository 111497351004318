import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import './Header.scss';
import Logo from "../../Logo/Logo";

const Header = () => {
    return (
        <div className='header'>
            <div className='content-header'>
                <div>
                    <Logo/>
                </div>
                <div className='brands'>
                    <a href='https://wa.me/56973261863/?text=Hola! Vengo desde radio Edukatel'>
                        <FontAwesomeIcon icon={faWhatsapp}/>
                    </a>
                    <a href='mailto:contacto@edukatel.cl'>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </a>
                    <a href='tel:+569 73261863'>
                        <FontAwesomeIcon icon={faPhone}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;