import React, {useRef, useState} from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from 'primereact/inputtextarea';
import Title from "../Title/Title";
import {Button} from "primereact/button";
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)
        emailjs.sendForm('service_9c4dpgh', 'template_l4h2jej', form.current, 'rEq-5slf38JwyPZhS')
            .then(() => {
                setLoading(false)
                alert('Correo enviado')
            }, () => {
                setLoading(false)
                alert('No se pudo enviar el correo')
            });
    }

    return (
        <>
            <Title>Contacto</Title>
            <form onSubmit={sendEmail} ref={form}>
                <div style={{display: 'flex', flexDirection: 'column', padding: '0 20px', gap: '20px'}}>
                    <InputText keyfilter={'email'} name={'email'} id={'name'}
                               placeholder='Escribe tu correo'/>
                    <InputTextarea autoResize name={'message'} id={'message'}
                                   placeholder='Escribe tu mensaje' rows={5} cols={30}/>
                    <Button disabled={loading} onClick={sendEmail} type='submit' label={loading ? 'Cargando' : 'Enviar'}
                            style={{backgroundColor: '#ee1264', border: 'unset', cursor: 'pointer'}}/>
                </div>
            </form>
        </>

    );
};

export default ContactForm;