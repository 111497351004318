import React, {useState, useRef, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './Music.scss';
import {BASE_URL} from "../../../../utils/const";
import {faPlay, faPause} from "@fortawesome/free-solid-svg-icons";

const Music = ({podcast}) => {
    const {title, cover, audio, default: def, thumbnail} = podcast;
    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        if (!def) {
            setIsPlaying(true)
        }
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
    }, [def, podcast])

    const handlerPlay = () => {
        if (isPlaying) {
            audioRef.current.pause()
            setIsPlaying(false)
        } else {
            audioRef.current.play()
            setIsPlaying(true)
        }
    }

    const handlerUpdateDuration = () => {
        if (isNaN(audioRef.current.duration)) {
            setDuration(0);
        } else if (audioRef.current.duration === Infinity) {
            setDuration(999);
        } else {
            setDuration(audioRef.current.duration);
        }
        setCurrent(audioRef.current.currentTime);
    }

    const readableDuration = (seconds) => {
        let sec = Math.floor(seconds);
        let min = Math.floor(sec / 60);
        min = min >= 10 ? min : '0' + min;
        sec = Math.floor(sec % 60);
        sec = sec >= 10 ? sec : '0' + sec;
        return min + ':' + sec;
    }

    return (
        <div className='container-music'>
            <div id='container-cover' className='container-cover'>
                <img className='cover' alt='default-cover' src={def ? cover : BASE_URL + thumbnail}/>
            </div>
            <div id='title' className='title-music'>
                {title}
            </div>
            <div id='controls' className='controls'>
                <FontAwesomeIcon onClick={handlerPlay} style={{cursor: 'pointer'}} icon={isPlaying ? faPause : faPlay} size='xl'/>
                <p style={{padding: '0 10px'}}>{readableDuration(current)} / {readableDuration(duration)}</p>
                <audio controls autoPlay ref={audioRef} style={{visibility: 'hidden'}}
                       onTimeUpdate={handlerUpdateDuration} preload="metadata">
                    <source src={def ? audio : BASE_URL + audio} type="audio/mpeg"/>
                </audio>
            </div>
        </div>
    );
};

export default Music;