import React from 'react';

const Videos = () => {
    return (
        <div style={{textAlign: 'center', padding: '30px 0'}}>
            <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/videoseries?list=PLRytJ-rG04EEuNtMHeI735fJ5OFCdAQZO"
                    title="Edukatel" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen/>
        </div>
    );
};

export default Videos;