import React from 'react';
import logo from '../../media/img/logo-en-blanco.png';
import './Logo.scss'

const Logo = ({onCLick}) => {
    return (
        <img id='logo' onClick={onCLick} alt='logo' src={logo} className='logo' />
    );
};

export default Logo;