import React from 'react';
import './Podcasts.scss';
import Title from "../../../../components/Title/Title";
import Program from "../Program/Program";
import Videos from "../Videos/Videos";

const Podcasts = ({podcasts, onClick, currentPodcast}) => {
    console.log(podcasts)
    return (
        <div className='main-podcast'>
            <Title>
                Programas
            </Title>
            <div id='podcasts' className='podcasts'>
                <div>
                    {
                        podcasts && podcasts.map((podcast, index) => {
                            return <Program
                                key={index}
                                podcast={podcast}
                                onClick={() => onClick(podcast)}
                                showInProgress={podcast.id === currentPodcast.id}
                            />
                        })
                    }
                </div>
            </div>
            <Videos/>
        </div>
    );
};

export default Podcasts;